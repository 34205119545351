body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

h1 {
  font-family: 'Roboto-Bold';
  font-color: white;
  font-size: 42px;
}

@font-face {
  font-family: 'Roboto-Black';
  src: local('Roboto-Black'), url(./Roboto-Black.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-Bold';
  src: local('Roboto-Bold'), url(./Roboto-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-Regular';
  src: local('Roboto-Regular'), url(./Roboto-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-Light';
  src: local('Roboto-Light'), url(./Roboto-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-Thin';
  src: local('Roboto-Thin'), url(./Roboto-Thin.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-Medium';
  src: local('Roboto-Medium'), url(./Roboto-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Averta-Black';
  src: local('Averta-Black'), url(./Averta-Black.ttf) format('truetype');
}

@font-face {
  font-family: 'Averta-Bold';
  src: local('Averta-Bold'), url(./Averta-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Averta-Regular';
  src: local('Averta-Regular'), url(./Averta-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Averta-Semibold';
  src: local('Averta-Semibold'), url(./Averta-Semibold.ttf) format('truetype');
}


@font-face {
  font-family: 'ClanOT-Ultra';
  src: local('ClanOT-Ultra'), url(./ClanOT-Ultra.ttf) format('truetype');
}
@font-face {
  font-family: 'ClanOT-Black';
  src: local('ClanOT-Black'), url(./ClanOT-Black.ttf) format('truetype');
}
@font-face {
  font-family: 'ClanOT-Bold';
  src: local('ClanOT-Bold'), url(./ClanOT-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'ClanOT-Medium';
  src: local('ClanOT-Medium'), url(./ClanOT-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'ClanOT-News';
  src: local('ClanOT-News'), url(./ClanOT-News.ttf) format('truetype');
}


@font-face {
  font-family: 'Inter-Black';
  src: local('Inter-Black'), url(./Inter-Black.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter-Bold';
  src: local('Inter-Bold'), url(./Inter-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter-Medium';
  src: local('Inter-Medium'), url(./Inter-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter-SemiBold';
  src: local('Inter-SemiBold'), url(./Inter-SemiBold.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter-Regular';
  src: local('Inter-Regular'), url(./Inter-Regular.ttf) format('truetype');
}


@font-face {
  font-family: 'Metropolis-Black';
  src: local('Metropolis-Black'), url(./Metropolis-Black.otf) format('truetype');
}
@font-face {
  font-family: 'Metropolis-Bold';
  src: local('Metropolis-Bold'), url(./Metropolis-Bold.otf) format('truetype');
}
@font-face {
  font-family: 'Metropolis-Medium';
  src: local('Metropolis-Medium'), url(./Metropolis-Medium.otf) format('truetype');
}
@font-face {
  font-family: 'Metropolis-Regular';
  src: local('Metropolis-Regular'), url(./Metropolis-Regular.otf) format('truetype');
}

@font-face {
  font-family: 'Tahoma-Regular2';
  src: local('Tahoma-Regular'), url(./Tahoma-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Tahoma-Bold2';
  src: local('Tahoma-Bold'), url(./Tahoma-Bold.TTF) format('truetype');
}
